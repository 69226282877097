.footer{
        bottom: 0px;
        padding-bottom: 16px;
        scroll-behavior: none;
        padding-top: 30px;

        &__contact{
            color: var(--color-text);
            text-decoration: none;
        }
}

body[data-menu] .footer{
    
    background-color: var(--color-bg2);
    padding-left: 6.4vw;
    display: block;
    padding-top: 3rem;

    &::before{
        content: "";
        position: absolute;
        width: calc(100vw - 12.8vw);
        height: 1px;
        background-color: var(--color-text);
        opacity: 1;
        top: 0;
    }

    &__contact{
        display: flex;
        flex-direction: column;
        margin: 0;

        &__cnt{
            font-size: 17px;
        }
    }

    &__list{
        padding: 0;
        display: flex;
        justify-content: space-between;
        width: 100%;
        
        * {
            font-size: 15px;
        }
    }
}

.cta{
    display: none;
}

@media screen and (min-width: 768px){
    .footer__contact{
        animation: welcome 1s;
        &__cnt{
            display: none;
        }
    }
    .cta{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 6rem 3.2vw;
        padding-bottom: 3rem;
        background-color: var(--color-bg);


        &::after{
            content: "";
            position: absolute;
            width: 0;
            height: 1px;
            background-color: var(--color-text);
            margin-top: 74px;
            left: 6.4vw;
            transition: width 1.5s;
        }
        
        &:hover{

            &::after{
                content: "";
                position: absolute;
                width: calc(100vw - 12.8vw);
                height: 1px;
                background-color: var(--color-text);
                margin-top: 74px;
                left: 6.4vw;
                transition: width 1.5s cubic-bezier(0.75,0,0,1);
            }
        }

        &__hi{
            text-transform: uppercase;
            opacity: 0.4;
            font-size: 12px;
            padding-top: 16px;
        }

        &__title{
          
            &::after{
                content: "";
                position: absolute;
                width: calc(100vw - 12.8vw);
                height: 1px;
                background-color: var(--color-text);
                margin-top: 48px;
                opacity: 0.35;
                left: 6.4vw;
            }
        }
    }
}

/* @media screen and (min-width: 1024px){

} */