.hero{
    position: relative;
    padding: 7rem 0px;
    margin: 0px 6vw;
    background-color: var(--color-bg2);
    height: 65vh;

    > *{
        padding: 0px 3.2vw;
    }
    
    &__title *{
        display: flex;
    }

    &__title__status {
        max-width: 50vw;
        display: block;
        margin-top: 12px;
    }

    &__text{
        margin-top: 3rem;

        &__last{
            text-transform: uppercase;
            opacity: 0.8;
            font-size: 15px;
            max-width: 212px;
            padding-top: 1rem;
        }
    }

    .link--text{
        color: var(--color-text);

        &:hover, &:focus{
            color: var(--color-details);
            transition: 0.2s;
        }
        &:active{
            color: var(--color-text);
        }
    }

    &__span{
        display: none;
    }

}

@media screen and (min-width: 475px) {
    .hero{
        margin: 0px 3.2vw;
        padding-bottom: 0;
    }
}

@media screen and (min-width: 768px) {

    .hero{
        height: 60vh;
        padding-top: 11rem;

        &__title{
            margin-left: 0;
        }

        &__text{
            display: flex;
            justify-content: space-between;
            *{
                max-width: 364px;
            }

            &__last{
                max-width: 212px;
                padding-top: 0;
            }
        }
    }
}

@media screen and (min-width: 1024px) {

    .hero{
        position: relative;
        display: flex;
        justify-content: space-between;
        margin: 0px 3.2vw;
        padding-top: 10rem;
        padding-bottom: 12rem;
        height: 50vh;

        &__title{
            width: 50vw;
        }

        &__text{
            display: inherit;
            max-width: 439px;
            margin-top: -5px;

            &__last{
                position: absolute;
                bottom: 6rem;
                max-width: 364px;
            }
        }
    
        &__span{
            position: absolute;
            bottom: 6rem;
            left: 0;
            display: block;

            & svg{
                padding-left: 4px;
            }
        }
    }
}
