html{
    scroll-behavior: smooth;
    /* add scroll snap */
}

body{
    font-family: "neue-haas-grotesk-display", sans-serif;
    font-size: 17px;
    font-weight: 500;
    line-height: 1.55;
    color: var(--color-text);
    margin: 0;
}

/* mobile & tablet TYPE */
.title{
    line-height: 1;
    
    &--h1{
        font-size: 48px;
        font-weight: 500;
        margin: 0;
        letter-spacing: -2px;
    }

    &--h2{
        font-size: 24px;
        font-weight: 500;
        margin: 0;
    }
    
    transition: 0.2s;
}

.p-bold{
    color: var(--color-details);
}

.hglt{
    font-size: 14px;
    text-transform: uppercase;
    margin: 0%;
    opacity: 0.5;
}

.copyright, .small{
    font-size: 15px;
}
.descr{
    opacity: 0.75;
}



@media (min-width: 768px){ 

    body{
        line-height: 1.65;
    }

    .title{
        &--h1{
            font-size: 90.44px;
        }
        &--h2{
            font-size: 24px;
        }

        transition: 0.2s;
    }
 }

/* DESKTOP */
@media (min-width: 1155px){

    body{
        line-height: 1.40;
        font-size: 16px;
    }
    /* 1.444 ratio, 16px */
    .title{
        &--h1{
            font-size: 112px;
        }
        &--h2{
            font-size: 23px;
        }

        transition: 0.2s;
    }

    .copyright{
        font-size: 14px;
    }

    .hglt{
        font-size: 16px;
    }
}


*::selection {
    background: var(--color-details);
    color: #fff;
}

.p-bold::selection {
    background: var(--color-details);
    color: #000;
}