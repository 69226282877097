body::-webkit-scrollbar{
    display: none;
}

body{
    -ms-overflow-style: none;
    scrollbar-width: none;
    background-color: var(--color-bg);
} 

.hidden{
    display: none;
}