.main{
    margin: 0px 6vw;
    padding: 0px 0vw;
    padding-bottom: 6rem;
    background-color: var(--color-bg2);
}

.work {
    padding-top: 6rem;
    background-color: var(--color-bg2);

    > *{
        margin-bottom: 6rem;

        &:last-child{
            margin-bottom: 0;
        }
    }

    &__prj{
        width: 100%;
        overflow: hidden;

        &__img{
               width: 100%; 
               height: 500px;
               object-fit: cover;
               background-repeat: no-repeat;
        }

        &__visual{
            position: relative;
            transition: all 1s cubic-bezier(0.075, 0, 0, 1);

            &:hover{
                transform: scaleX(1.01);
                transition: 1s cubic-bezier(0.075, 0, 0, 1);
            }

            &:active{
                body{
                    display: none;
                    background-color: var(--color-text);
                }
            }

        }

        &__logo{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        &__content{
            margin-top: 1rem;
            padding: 0 3.2vw;
        }
    }
}
/* 
@media (-webkit-min-device-pixel-ratio: 2), 
(min-resolution: 120dpi) { 

}
 */


@media screen and (min-width: 768px){
    .main{
        max-width: calc(100vw - 6.4vw);
        margin: 0px 3.2vw;
    }

    .work{
        padding-top: 0;
        padding-bottom: 3rem;
        margin: 0 3.2vw;

        &__prj{
            display: flex;
            flex-direction: column;
            margin-bottom: 9rem;

            &__img{
                width: calc(100vw - 12.8vw);

                &--1, &--5, &--7{
                    height: 550px;
                }
                &--2, &--6{
                    height: 550px;
                }
                &--5, &--4, &--3{
                    height: 450px;
                }
            }
            
            &__content{
                margin-top: 1rem;
                text-align: left;
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                padding-right: 0;
                padding-left: 0;

                &__descr{
                    margin-top: -1rem;
                    max-width: 350px;
                }
            }

            &:last-child{
                margin-bottom: 6rem;
            }
        }
    }
}



@media screen and (min-width: 1250px){

    .work{
        margin: 0 6.4vw;
        padding-top: 12rem;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        flex-direction: inherit;

       &__prj{
        max-width: 540px;
        margin: 0;

        &:nth-child(2n+1) {
            margin-top: 9rem;
          } 

        &:nth-child(2n) {
            margin-top: -9rem;
          } 

        &--7{
            max-width: 1240px;
        }

           &__img{
               max-width: 540px;
               
               &--7{
                max-width: 1240px;
            }
           }
           &__content{
               flex-direction: column;

               &__title{
                   display: flex;
                   align-items: flex-end;
                   width: 100%;

                   & span{
                    margin-left: 8px;
                    font-size: 14px;
                   }
               }

               &__descr{
                   margin-top: 0px;
               }
           }
       }
    }
}

@media screen and (min-width: 1251px){

    .work{
        max-width: 1240px;
        margin: auto;
    }
}