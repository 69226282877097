/* fontfaces */

@media(prefers-color-scheme: light){
 
:root{
    --color-bg: #fff;
    --color-bg2: #F8F8F8;
    --color-text: #000;
    --color-text-offset: #fff;
    --color-details: #FF5C00;

    --font-regular: 400;
}  
}

@media(prefers-color-scheme: dark){
    :root{
        --color-bg: #000;
        --color-bg2: #141414;
        --color-text: #fff;
        --color-text-offset: #000;
        --color-details: #FF5C00;
    }
}