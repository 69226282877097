
@keyframes textSlide {
    from {
        color: transparent;
        transform: translateY(10px);
    }
                      
    to {
        color: var(--color-text);
        transform: translateY(0px);
    }
}

@keyframes reveal {
    from {
      transform: translateY(200px);
    }
    to {
      transform: translateY(0);
    }
  }

  @keyframes loop {
    from {
        transform: translateY(-4px);
    }
  
    to {
        transform: translateY(2px);
    }
  }

/* anims elements */

.menu__list, .footer *, .footer__contact, .menu__logo {
    animation: textSlide 1s ease-out;
    animation-delay: -0.03s;
}

  h1 {
    margin: 0;
    overflow: hidden;
  }

  h1 span {
    display: block;
    animation: reveal 1.5s cubic-bezier(0.75, 0, 0, 1.05) ;
    animation-delay: -0.5s;
  }

  .hero__span svg{
    animation: loop 1.25s ease-in;
    animation-iteration-count: infinite;
    animation-delay: 3s;
  }

  @media screen and (max-width: 765px){
      .menu__logo{
          animation: none;
      }

      .menu__list, .footer__contact, .footer * {
        animation: textSlide 0.7s ease-in-out;
        animation-delay: -0.03s;
    }
  }