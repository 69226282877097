.menu, .footer{
    position: fixed;
    scroll-behavior: none;
    width: 100vw;
    background-color: var(--color-bg);
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1;

    &__list{
        display: none;
    }

    &__logo, &__contact{
        margin-left: 6.4vw;
        position: relative;
        z-index: 12;
        text-decoration: none;
        color: var(--color-text);
    }

    &__logo{
        padding-top: 7px;
    }

    &__ctn{
        display: flex;
        margin-right: 6.4vw;
    }
    &__burger{
        position: relative;
        z-index: 3;
        padding-top: -5px;
        
        &:hover{
            cursor: pointer;
        }
    }
    &__btn{
        border: none;
        background-color: transparent;
    }
}

.menu{
    padding-bottom: 1rem;
    padding-top: 16px;
}

.footer__list, .footer__contact{
    display: none;
}

.cross{
    &--inherit{
        display: none;
    }
}


/* BURGER MENU */

body[data-menu] .menu {

    &__list{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        position: fixed;
        top: -16px;
        left: 0;
        height: 100vh;
        width: 100vw;
        background: var(--color-bg2);
        padding: 0 6.4vw;
        padding-top: 9rem;

        &__item{
            display: flex;
            margin-top: 1.5rem;
            width: 100%;
            position: relative;
    
            > a {
                text-decoration: none;
                color: var(--color-text);
                font-size: 24px;
                text-transform: uppercase;
            }
        }
    }

    .circle{
        &--none{
            display: none;
        }
    }

    .cross{
        &--inherit{
            display: inherit;
        }
    }
}




@media screen and (min-width: 768px){
    .footer__list, .footer__contact{
        display: flex;
    }


    .link{

        &__hd{
            overflow: hidden;
        }

        &__rel{
            position: relative;
        }

        &::before {
            content: "";
            position: absolute;
            top: -1rem;
            left: -1rem;
            bottom: -1rem;
            right: -1rem;
        }

        &:hover &__text {
            transform: translateY(-100%);
        }

        &__inner:after {
            content: attr(data-text);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            color: currentColor;
            transform: translateY(100%);
        }

        &:hover &__inner:after {
            transform: translateY(0px);
        }

        &__inner:after, &__text {
            transition: transform .75s cubic-bezier(.19,1,.22,1);
        }
    }




    .menu, .footer{
          
        &__btn{
            display: none;
        }
        &__list{
            display: flex;
            list-style-type: none;
            margin-right: 6.4vw;
            
            &__item{
                margin-right: 32px;
                position: relative;
            
                &:last-child{
                    margin: 0%;
                }
                
                > *{
                    text-decoration: none;
                    color: var(--color-text);
                }

                &:hover, &:focus{

                    &::before{
                        content: "";
                        position: absolute;
                        width: 4px;
                        height: 4px;
                        border-radius: 5px;
                        background-color: var(--color-text);
                        left: -10px;
                        top: 10px;
                    }
                }

                &:active{
                    &::before{
                        display: none;
                    }
                }
            }
        }

        &__ctn{
           margin-right: 0px;
        }

        &__burger{
           display: none;
        }
    }
}